import { render, staticRenderFns } from "./Collect.vue?vue&type=template&id=dbd57c04&"
import script from "./Collect.vue?vue&type=script&lang=js&"
export * from "./Collect.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dbd57c04')) {
      api.createRecord('dbd57c04', component.options)
    } else {
      api.reload('dbd57c04', component.options)
    }
    module.hot.accept("./Collect.vue?vue&type=template&id=dbd57c04&", function () {
      api.rerender('dbd57c04', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/insurance-payments/details/Collect.vue"
export default component.exports