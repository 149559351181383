var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.canVerb(_vm.resource, "index")
    ? _c(
        "div",
        { staticClass: "mx-1" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "left" } },
                [
                  _c(
                    "b-button-group",
                    {
                      directives: [
                        {
                          name: "b-toggle",
                          rawName: "v-b-toggle:collapse-1",
                          arg: "collapse-1",
                        },
                      ],
                      staticClass: "my-2 filter-button-group",
                      attrs: { title: _vm.getDefaultFilterMessage() },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "when-open" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "when-closed" },
                        [
                          _c("b-icon", {
                            attrs: { icon: "funnel", "font-scale": "1.5" },
                          }),
                        ],
                        1
                      ),
                      _vm._v("\n        Filtra\n      "),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.canVerb(_vm.resource, "store")
                ? _c(
                    "b-col",
                    { attrs: { align: "right" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddInsurancePayment(2)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "calendar2-event",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Genera\n        Quietanze"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea con sospesi",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddInsurancePayment(0)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "bookmark-plus",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Includi\n        Sospesi"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea con acconti",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddInsurancePayment(3)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "cash-stack",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Includi\n        Acconti"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea con arretrati",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddInsurancePayment(1)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "calendar2-plus",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" Includi\n        Arretrati"),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-create mb-1",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea manuale",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onAddInsurancePayment(4)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: { icon: "receipt", "aria-hidden": "true" },
                          }),
                          _vm._v(" Crea\n        Manuale"),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-collapse",
            { attrs: { visible: "", id: "collapse-1" } },
            [
              _c(
                "b-form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSearch(_vm.filterName)
                    },
                  },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "filter" },
                    [
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "payment_type",
                                label: "Tipo",
                                options: _vm.payment_types,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byAttribute.payment_type,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byAttribute,
                                    "payment_type",
                                    $$v
                                  )
                                },
                                expression: "filter.byAttribute.payment_type",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "status_payment",
                                label: "Stato",
                                options: _vm.status_payments,
                              },
                              model: {
                                value: _vm.filter.byAttribute.status_payment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byAttribute,
                                    "status_payment",
                                    $$v
                                  )
                                },
                                expression: "filter.byAttribute.status_payment",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Produttore",
                                label: "Produttore",
                                options: _vm.salesmen,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byBrokerEnhanced.salesmen,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byBrokerEnhanced,
                                    "salesmen",
                                    $$v
                                  )
                                },
                                expression: "filter.byBrokerEnhanced.salesmen",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Collaborazione",
                                label: "Collaborazione",
                                options: _vm.cooperators,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byBrokerEnhanced.cooperators,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byBrokerEnhanced,
                                    "cooperators",
                                    $$v
                                  )
                                },
                                expression:
                                  "filter.byBrokerEnhanced.cooperators",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Compagnia",
                                label: "Compagnia",
                                options: _vm.companies,
                              },
                              model: {
                                value: _vm.filter.byInsurer.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byInsurer, "id", $$v)
                                },
                                expression: "filter.byInsurer.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Tipo Polizza",
                                label: "Tipo Polizza",
                                options: _vm.risks,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value: _vm.filter.byRiskType.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byRiskType, "id", $$v)
                                },
                                expression: "filter.byRiskType.id",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "number",
                                type: "text",
                                label: "Numero Polizza",
                                placeholder: "Inserisci un numero polizza",
                              },
                              model: {
                                value: _vm.filter.byInsurancePolicy.number,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsurancePolicy,
                                    "number",
                                    $$v
                                  )
                                },
                                expression: "filter.byInsurancePolicy.number",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: { name: "periodo", label: "Periodo da" },
                              model: {
                                value: _vm.filter.byCalendar.from,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "from", $$v)
                                },
                                expression: "filter.byCalendar.from",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-datepicker", {
                              attrs: { name: "periodo", label: "Periodo a" },
                              model: {
                                value: _vm.filter.byCalendar.to,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byCalendar, "to", $$v)
                                },
                                expression: "filter.byCalendar.to",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "nominativo",
                                type: "text",
                                label: "Nominativo",
                                placeholder: "Inserisci un nominativo",
                              },
                              model: {
                                value: _vm.filter.byRegistry.title,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filter.byRegistry, "title", $$v)
                                },
                                expression: "filter.byRegistry.title",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-input", {
                              attrs: {
                                name: "Gruppo",
                                type: "text",
                                label: "Gruppo",
                                placeholder: "Inserisci un gruppo",
                              },
                              model: {
                                value: _vm.filter.byRegistryGroup.code,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byRegistryGroup,
                                    "code",
                                    $$v
                                  )
                                },
                                expression: "filter.byRegistryGroup.code",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Frazionamento",
                                label: "Frazionamento",
                                options: _vm.payment_splitting,
                                taggable: true,
                                multiple: true,
                                closeOnSelect: false,
                              },
                              model: {
                                value:
                                  _vm.filter.byInsurancePolicy
                                    .payment_splitting,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsurancePolicy,
                                    "payment_splitting",
                                    $$v
                                  )
                                },
                                expression:
                                  "filter.byInsurancePolicy.payment_splitting",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "IBAN",
                                label: "SEPA SDD",
                                options: _vm.iban_options,
                              },
                              model: {
                                value: _vm.filter.byInsurancePolicy.IBAN,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byInsurancePolicy,
                                    "IBAN",
                                    $$v
                                  )
                                },
                                expression: "filter.byInsurancePolicy.IBAN",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "col-md-3" },
                          [
                            _c("base-select", {
                              attrs: {
                                name: "Da Incassare",
                                label: "Da Incassare",
                                options: _vm.to_collect_options,
                              },
                              model: {
                                value: _vm.filter.byAttribute.to_collect,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.filter.byAttribute,
                                    "to_collect",
                                    $$v
                                  )
                                },
                                expression: "filter.byAttribute.to_collect",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b-row", [
                        _c(
                          "div",
                          { staticClass: "form-group col-md-3 align-self-end" },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle:collapse-1",
                                    arg: "collapse-1",
                                  },
                                ],
                                attrs: {
                                  type: "submit",
                                  variant: "lisaweb",
                                  size: "sm",
                                  disabled: !_vm.formLoaded,
                                },
                              },
                              [_vm._v("Cerca")]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn-reset",
                                attrs: {
                                  type: "button",
                                  variant: "lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClearFilter(_vm.filterName)
                                  },
                                },
                              },
                              [_vm._v("Reset")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle:collapse-1-inner",
                              arg: "collapse-1-inner",
                            },
                          ],
                          staticClass: "my-2 filter-button-group",
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "when-open" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticClass: "when-closed" },
                            [
                              _c("b-icon", {
                                attrs: { icon: "funnel", "font-scale": "1.5" },
                              }),
                            ],
                            1
                          ),
                          _vm._v("\n          Altri Filtri\n        "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: { id: "collapse-1-inner" },
                        },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "Commenti",
                                    label: "Commenti",
                                    options: _vm.comments,
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.comment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "comment",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.comment",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: {
                                    name: "CercaTestoCommenti",
                                    label: "Cerca Testo nei Commenti",
                                  },
                                  model: {
                                    value: _vm.filter.byCustom.comment.value,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byCustom.comment,
                                        "value",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byCustom.comment.value",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "Pagamento",
                                    label: "Stato Pagamento",
                                    options: _vm.pagamenti,
                                  },
                                  model: {
                                    value:
                                      _vm.filter.byInsuranceAncillary
                                        .status_ancillary,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsuranceAncillary,
                                        "status_ancillary",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byInsuranceAncillary.status_ancillary",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "Contraenti",
                                    label:
                                      "Contraenti con più scadenze nel mese",
                                    options: _vm.contraenti,
                                  },
                                  model: {
                                    value: _vm.filter.byRelations,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.filter, "byRelations", $$v)
                                    },
                                    expression: "filter.byRelations",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "QuietanzeModificate",
                                    label:
                                      "Quietanze modificate (forzature / cambio premio)",
                                    options: _vm.modificate,
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.modified,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "modified",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.modified",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "anagrafica",
                                    label: "Tipo anagrafica",
                                    options: _vm.tipo_anagrafica,
                                  },
                                  model: {
                                    value:
                                      _vm.filter.byRegistry.status_registry,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byRegistry,
                                        "status_registry",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "filter.byRegistry.status_registry",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "related_notes",
                                    label:
                                      "Elenca solo quietanze con note associate",
                                    options: _vm.relatedNotesOptions,
                                  },
                                  model: {
                                    value: _vm.filter.byRelatedTasks,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "byRelatedTasks",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byRelatedTasks",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "lastInstallmentOptions",
                                    label: "Scadenza",
                                    options: _vm.lastInstallmentOptions,
                                  },
                                  model: {
                                    value: _vm.filter.byLastInstallment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter,
                                        "byLastInstallment",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byLastInstallment",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-input", {
                                  attrs: { name: "CONV", label: "Convenzioni" },
                                  model: {
                                    value: _vm.filter.byInsurancePolicy.CONV,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byInsurancePolicy,
                                        "CONV",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byInsurancePolicy.CONV",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-overlay",
            {
              attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "overlay",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", { attrs: { id: "cancel-label" } }, [
                              _vm._v("Operazione in corso..."),
                            ]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1652880295
              ),
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isLoading,
                      expression: "!isLoading",
                    },
                  ],
                },
                [
                  _c("insurance-payments", {
                    ref: _vm.tableRef,
                    attrs: {
                      onlyActions: ["infomodal"],
                      fields: _vm.fields,
                      repository: _vm.repository,
                      resource: _vm.resource,
                      filterName: _vm.filterName,
                      filterOn: {
                        byRelations: [
                          "byRegistry",
                          "byRegistryGroup",
                          "byHistory",
                          //'byTask',
                          "byUser",
                        ],
                      },
                      includeRowSelector: "",
                    },
                    on: {
                      rowSelector: _vm.onUpdateStatus,
                      selezionato: _vm.onUpdateSelected,
                      spunta: _vm.onSelectAll,
                      toglispunta: _vm.onUnselectAll,
                      open_note: _vm.openNoteModal,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-1",
                      attrs: {
                        type: "button",
                        variant: "lisaweb",
                        disabled: false,
                      },
                      on: { click: _vm.onSendCommunications },
                    },
                    [_vm._v("Invia selezionati")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("insurance-payment-modal", {
                ref: _vm.modalRef,
                attrs: {
                  beFormCreate: _vm.beForm["insurance_payment"],
                  beRulesCreate: _vm.beRules["insurance_payment"],
                },
                on: { create: _vm.onCreate, destroy: _vm.onDestroy },
                model: {
                  value: _vm.frm,
                  callback: function ($$v) {
                    _vm.frm = $$v
                  },
                  expression: "frm",
                },
              }),
              _vm._v(" "),
              _c("note-detail-modal", { attrs: { items: _vm.notes } }),
            ],
            1
          ),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "mt-3" },
        [
          _c("b-icon", { attrs: { icon: "info-circle", scale: "1.00" } }),
          _vm._v("\n  Non hai i permessi\n"),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }