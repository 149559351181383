var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _vm.hasInsurancePolicy
        ? _c("policies", {
            ref: _vm.tableRef,
            attrs: {
              fields: _vm.fields,
              repository: _vm.repository,
              resource: _vm.resource,
              filterName: _vm.filterName,
              onlyActions: ["infomodal", "edit"],
              noInnerWidth: "",
              noInputPaginator: "",
              noPagination: "",
            },
            on: { edit: _vm.onEdit },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.hasInsurancePolicy
        ? _c("p", [_vm._v("Polizza non presente")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }