var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-card",
        {
          staticClass: "mt-2",
          attrs: { header: "true", "header-tag": "header" },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
                _c("b", [_vm._v("Informazioni")]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field, index) {
            return _c("b-row", { key: index }, [
              _c("div", { staticClass: "col-md-4 border-bottom" }, [
                _vm._v(
                  "\n        " + _vm._s(field.label.toUpperCase()) + "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-8 border-bottom" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.renderDbRowData(field, _vm.dbRowData)) +
                    "\n      "
                ),
              ]),
            ])
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }