var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.id,
        size: "md",
        "ok-only": "",
        "ok-title": "Chiudi",
        "ok-variant": "lisaweb",
        "header-bg-variant": "lisaweb",
        "no-close-on-esc": "",
        "no-close-on-backdrop": "",
      },
      on: { ok: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function ({ cancel }) {
            return [
              _c(
                "div",
                {
                  staticClass: "modal-title",
                  attrs: { slot: "modal-title" },
                  slot: "modal-title",
                },
                [
                  _c("b-icon", {
                    attrs: { icon: _vm.titleIcon, "aria-hidden": "true" },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.titleLabel))]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    title: "Chiudi",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      cancel(), _vm.close()
                    },
                  },
                },
                [_vm._v("\n      ×\n    ")]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c("validation-observer", {
            ref: "observer",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ invalid, handleSubmit }) {
                  return [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [
                        _c(
                          "b-card",
                          { staticClass: "filter" },
                          [
                            _vm.inputVal.payment_type === 4
                              ? _c(
                                  "b-row",
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-6" },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "b-button-toolbar",
                                              [
                                                _c(
                                                  "b-button-group",
                                                  [
                                                    _c("base-input", {
                                                      attrs: {
                                                        name: "Titolo",
                                                        label: "Titolo",
                                                        readonly: true,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.inpt_label_ancillary,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.inpt_label_ancillary =
                                                            $$v
                                                        },
                                                        expression:
                                                          "inpt_label_ancillary",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mt-4 btn-quick",
                                                        attrs: {
                                                          size: "sm",
                                                          text: "Button",
                                                          variant: "lisaweb",
                                                          title:
                                                            "Ricerca Veloce",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.openQuickSearchAncillary,
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "search",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "mt-4 btn-quick ml-1",
                                                        attrs: {
                                                          size: "sm",
                                                          text: "Button",
                                                          variant: "lisaweb",
                                                          title:
                                                            "Elimina Titolo",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.resetAncillaryId,
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          attrs: {
                                                            icon: "trash",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("quick-search-ancillary-modal", {
                                      ref: "quickSearchAncillary",
                                      on: {
                                        input: _vm.handleAncillaryModalInput,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "form-group col-md-6" },
                                      [
                                        _vm.insurance_ancillary_data
                                          ? _c("span", {
                                              staticClass: "info",
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.toInfoData(
                                                    _vm.insurance_ancillary_data,
                                                    "insurance_ancillary",
                                                    0
                                                  )
                                                ),
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("b-row", [
                              _vm.inputVal.payment_type === 2
                                ? _c("div", { staticClass: "col-md-6" }, [
                                    _c(
                                      "div",
                                      [
                                        _c("base-select", {
                                          attrs: {
                                            vid: "insurer",
                                            label: "Compagnia",
                                            name: "Compagnia",
                                            options: _vm.companies,
                                            multiple: true,
                                            taggable: true,
                                            closeOnSelect: false,
                                            rules: { required: true },
                                          },
                                          model: {
                                            value: _vm.inputVal.insurer_id,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.inputVal,
                                                "insurer_id",
                                                $$v
                                              )
                                            },
                                            expression: "inputVal.insurer_id",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          {
                                            attrs: {
                                              pressed: _vm.myToggle,
                                              variant: "light",
                                            },
                                            on: {
                                              "update:pressed": function (
                                                $event
                                              ) {
                                                _vm.myToggle = $event
                                              },
                                              click: function ($event) {
                                                _vm.myToggle
                                                  ? _vm.selectAllInsurers()
                                                  : _vm.deselectAllInsurers()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.myToggle
                                                  ? "Deseleziona tutti"
                                                  : "Seleziona tutti"
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md-6" },
                                [
                                  _c("base-month-picker", {
                                    attrs: {
                                      id: _vm.inputVal.payment_type,
                                      vid: "periodo_da",
                                      name: "periodo_da",
                                      label: [0, 1, 3].includes(
                                        _vm.inputVal.payment_type
                                      )
                                        ? "Periodo di quietanzamento"
                                        : "Mese",
                                    },
                                    model: {
                                      value: _vm.inputVal.from,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.inputVal, "from", $$v)
                                      },
                                      expression: "inputVal.from",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              [0, 1, 3].includes(_vm.inputVal.payment_type)
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-6" },
                                    [
                                      _c("base-month-picker", {
                                        attrs: {
                                          vid: "periodo_a",
                                          name: "periodo_a",
                                          label: "Escludi antecedenti al",
                                          clearable: true,
                                        },
                                        model: {
                                          value: _vm.inputVal.to,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputVal, "to", $$v)
                                          },
                                          expression: "inputVal.to",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-2 ml-4 float-right",
                                attrs: {
                                  type: "button",
                                  disabled: invalid,
                                  variant: "outline-lisaweb",
                                  size: "sm",
                                },
                                on: {
                                  click: function ($event) {
                                    return handleSubmit(_vm.onSubmit)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      [0, 1, 3].includes(
                                        _vm.inputVal.payment_type
                                      )
                                        ? "Aggiungi"
                                        : "Salva"
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            [0, 1, 3].includes(_vm.inputVal.payment_type)
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-2 float-right",
                                    attrs: {
                                      type: "button",
                                      disabled: invalid,
                                      variant: "outline-lisaweb",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return handleSubmit(_vm.onDestroy)
                                      },
                                    },
                                  },
                                  [_vm._v("\n            Elimina\n          ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }