var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } },
      [
        _c(
          "div",
          [
            _c("b-table", {
              attrs: {
                busy: _vm.isLoading,
                "head-variant": "light",
                "no-local-sorting": true,
                items: _vm.items,
                fields: _vm.getAllFields,
                "current-page": _vm.currentPage,
                "per-page": "0",
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
                "sticky-header": _vm.height,
                "no-border-collapse": "",
                responsive: "",
                "show-empty": "",
                "empty-text": "Non ci sono informazioni da mostrare",
                small: "",
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
                "sort-changed": _vm.sortingChanged,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "table-busy",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center my-2" },
                          [
                            _c("base-icon", {
                              attrs: {
                                name: "loading",
                                width: "35",
                                height: "35",
                              },
                            }),
                            _vm._v(" "),
                            _c("p", [_vm._v("Operazione in corso...")]),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  _vm.hasChecks && !_vm.isCheckExclusive
                    ? {
                        key: "head(check)",
                        fn: function () {
                          return [
                            _c("base-checkbox", {
                              staticClass: "check",
                              attrs: { name: "all" },
                              on: { change: _vm.onSelectAllRows },
                              model: {
                                value: _vm.checkAll,
                                callback: function ($$v) {
                                  _vm.checkAll = $$v
                                },
                                expression: "checkAll",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      }
                    : null,
                  _vm.hasChecks
                    ? {
                        key: "cell(check)",
                        fn: function (row) {
                          return [
                            _c("base-checkbox", {
                              staticClass: "check",
                              attrs: { name: "check" },
                              on: {
                                input: function ($event) {
                                  return _vm.onSelectRow(
                                    $event,
                                    row.index,
                                    row.item
                                  )
                                },
                              },
                              model: {
                                value: row.item.check,
                                callback: function ($$v) {
                                  _vm.$set(row.item, "check", $$v)
                                },
                                expression: "row.item.check",
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(anomalie)",
                        fn: function ({ item }) {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getVirtualValue("errors", item)
                                ),
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(diff)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("diff", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(insurer_code)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.getVirtualValue("insurer_code", item)
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(perc)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("perc", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(ultimo)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("ultimo", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(coass)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("sharing", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(expired_at)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.getVirtualValue("covered_at", item)
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(payment_splitting)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.getVirtualValue("payment_splitting", item)
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(number)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("number", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(branch_and_type)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.getVirtualValue("branch_and_type", item)
                                ) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(produttore)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getBroker(item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(contraente)",
                        fn: function ({ item }) {
                          return [
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.getVirtualValue("name2", item)
                                ),
                              },
                            }),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(address_books)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("contatti", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(gruppo)",
                        fn: function ({ item }) {
                          return [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.getVirtualValue("gruppo", item)) +
                                "\n        "
                            ),
                          ]
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(communication)",
                        fn: function ({ item }) {
                          return _vm._l(
                            _vm.getVirtualValue("communication", item),
                            function (obj, index) {
                              return _c(
                                "span",
                                { key: index },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      "font-scale": "1.5",
                                      icon: obj.icon,
                                      title: obj.title,
                                    },
                                  }),
                                ],
                                1
                              )
                            }
                          )
                        },
                      }
                    : null,
                  _vm.items.length
                    ? {
                        key: "cell(commento)",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: {
                                      title: item.comment,
                                      placement: "left",
                                    },
                                    expression:
                                      "{\n              title: item.comment,\n              placement: 'left',\n            }",
                                  },
                                ],
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      item.comment
                                        ? `${item.comment.slice(0, 20)}...`
                                        : ""
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ]
                        },
                      }
                    : null,
                  {
                    key: "cell(rowSelector)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "action-buttons" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass: "mt-1 mr-1",
                                attrs: {
                                  size: "sm",
                                  variant: "lisaweb",
                                  disabled:
                                    row.item.status_payment.value == 3 ||
                                    row.item.status_payment.value == 4,
                                  title:
                                    "Cambia in " +
                                    (row.item.status_payment.value == 1 ||
                                    row.item.status_payment.value == 2
                                      ? "valido"
                                      : "da validare"),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onChosen(row.item)
                                  },
                                },
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon:
                                      row.item.status_payment.value == 1 ||
                                      row.item.status_payment.value == 2
                                        ? "award"
                                        : "award-fill",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "cell(info)",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "div",
                          { staticClass: "float-left ml-1 mr-2 mt-1" },
                          [
                            !_vm.hasSameAddress(item)
                              ? _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title:
                                      "Indirizzo contraente diverso da indirizzo corrispondenza",
                                    icon: "envelope",
                                  },
                                })
                              : _c("b-icon", {
                                  attrs: { "font-scale": "1.5", icon: "blank" },
                                }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        (
                          item.payable.insurance_policy
                            ? item.payable.insurance_policy.status_policy
                                .value === 3
                            : false
                        )
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-1 mr-2 mt-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title: "Polizza Sostituita",
                                    icon: "bell-fill",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.modified.value === "Y"
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-1 mr-2 mt-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title: "Forzatura Stato",
                                    icon: "flag-fill",
                                    variant: "danger",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          item?.payable?.insurance_policy
                            ? item?.payable?.insurance_policy?.attributables
                                .IBAN === "Y"
                            : false
                        )
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-1 mr-2 mt-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title: "Sepa/Rid",
                                    icon: "bank",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (
                          item?.payable?.insurance_policy
                            ? item?.payable?.insurance_policy?.attributables
                                .CONV
                            : false
                        )
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-1 mr-2 mt-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title:
                                      item?.payable?.insurance_policy
                                        ?.attributables.CONV,
                                    icon: "bag",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        (item?.to_collect.value === "Y" ? true : false)
                          ? _c(
                              "div",
                              { staticClass: "float-left ml-1 mr-2 mt-1" },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    "font-scale": "1.5",
                                    title: "Da incassare",
                                    icon: "check",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getVirtualValue("info", item).notes
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: _vm.getVirtualValue("info", item)
                                      .notes.content,
                                    expression:
                                      "getVirtualValue('info', item).notes.content",
                                  },
                                ],
                                staticClass:
                                  "info-box float-left mt-1 mr-2 ml-1",
                                attrs: {
                                  role: _vm.getVirtualValue("info", item)
                                    .notes_counter.content
                                    ? "button"
                                    : null,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.getVirtualValue("info", item)
                                      .notes_counter.content
                                      ? _vm.openNoteDetailModal(
                                          _vm.getVirtualValue("info", item)
                                            .notes.rawData
                                        )
                                      : null
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.getVirtualValue("info", item)
                                        .notes_counter.content
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getVirtualValue("info", item).premium
                          ? _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip",
                                    value: {
                                      title:
                                        "<b>" +
                                        _vm.getVirtualValue("info", item)
                                          .premium.header +
                                        "</b><br/>" +
                                        _vm.getVirtualValue("info", item)
                                          .premium.content,
                                      placement: "left",
                                      html: true,
                                    },
                                    expression:
                                      "{\n              title:\n                '<b>' +\n                getVirtualValue('info', item).premium.header +\n                '</b><br/>' +\n                getVirtualValue('info', item).premium.content,\n              placement: 'left',\n              html: true,\n            }",
                                  },
                                ],
                                staticClass:
                                  "info-box float-left mt-1 mr-2 ml-1",
                              },
                              [
                                _c("b-icon", {
                                  attrs: {
                                    icon: "currency-euro",
                                    variant: "danger",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "cell(selezionato)",
                    fn: function ({ item }) {
                      return [
                        _c("input", {
                          attrs: { type: "hidden" },
                          domProps: { value: _vm.calculateItemSelected(item) },
                        }),
                        _vm._v(" "),
                        _c("base-icon", {
                          staticClass: "hide-it",
                          staticStyle: { position: "relative", top: "-2px" },
                          attrs: { name: "loading", width: "15", height: "15" },
                        }),
                        _vm._v(" "),
                        _c("base-checkbox", {
                          ref: `cbx-${item.id}`,
                          staticClass: "check",
                          attrs: {
                            name: "selected",
                            chk_val: "Y",
                            unchk_val: "N",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.onSelezionato($event, item)
                            },
                          },
                          model: {
                            value: item.selected.value,
                            callback: function ($$v) {
                              _vm.$set(item.selected, "value", $$v)
                            },
                            expression: "item.selected.value",
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(actions)",
                    fn: function (row) {
                      return [
                        _c(
                          "div",
                          { staticClass: "action-buttons" },
                          [
                            _vm.actions.includes("infomodal")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Modale Dettagli",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openModal(
                                          row.item,
                                          row.index,
                                          $event.target
                                        )
                                      },
                                    },
                                  },
                                  [_c("b-icon", { attrs: { icon: "eye" } })],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("details")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: row.detailsShowing
                                        ? "Chiudi dettagli"
                                        : "Apri dettagli",
                                    },
                                    on: { click: row.toggleDetails },
                                  },
                                  [
                                    row.detailsShowing
                                      ? _c("b-icon", {
                                          attrs: { icon: "chevron-up" },
                                        })
                                      : _c("b-icon", {
                                          attrs: { icon: "chevron-down" },
                                        }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("edit")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Modifica",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onEdit(row.item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: { icon: "pencil-square" },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.actions.includes("destroy")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-1 mr-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "lisaweb",
                                      title: "Elimina",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDestroy(row.item.id)
                                      },
                                    },
                                  },
                                  [_c("b-icon", { attrs: { icon: "trash" } })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "row-details",
                    fn: function (row) {
                      return [
                        _c(
                          "b-card",
                          _vm._l(row.item, function (value, key) {
                            return _c(
                              "b-row",
                              { key: key, staticClass: "mb-2" },
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "text-sm-right",
                                    attrs: { sm: "3" },
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        _vm._s(_vm._f("capitalize")(key)) + ":"
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("b-col", [_vm._v(_vm._s(value))]),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.hasChecksButtonGroup
          ? _c(
              "div",
              [
                _c(
                  "b-button-group",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "max-width": "max-content",
                    },
                    attrs: { size: "md" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "text-decoration-none",
                        attrs: { variant: "transparent" },
                        on: {
                          click: function ($event) {
                            return _vm.spuntaTutte()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "check-icon",
                          attrs: { icon: "check" },
                        }),
                        _vm._v("Spunta tutte\n        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        staticClass: "text-decoration-none",
                        attrs: { variant: "transparent" },
                        on: {
                          click: function ($event) {
                            return _vm.togliSpuntaTutte()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          staticClass: "cross-icon",
                          attrs: { icon: "x" },
                        }),
                        _vm._v("Togli spunte a tutte\n        "),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        staticClass: "select-all",
                        staticStyle: { margin: "0", padding: "5px 10px" },
                      },
                      [
                        _vm._v(
                          "\n          Selezionate: " +
                            _vm._s(_vm.cntr) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.noPagination
          ? _c("b-row", [
              _c("div", { staticClass: "show-text" }, [
                _c("p", [_vm._v("Mostra")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "group" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        "label-for": "per-page-select",
                        "label-cols-sm": "3",
                        "label-cols-md": "",
                        "label-cols-lg": "3",
                        "label-size": "sm",
                        md: "auto",
                      },
                    },
                    [
                      _c("b-form-select", {
                        staticStyle: { width: "68px" },
                        attrs: {
                          id: "per-page-select",
                          options: _vm.pageOptions,
                          size: "sm",
                          disabled: !_vm.items.length,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.onPageChange(1)
                          },
                        },
                        model: {
                          value: _vm.perPage,
                          callback: function ($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "page" }, [
                _c("p", [
                  _vm._v("di " + _vm._s(_vm.totalItems) + " per pagina"),
                ]),
              ]),
              _vm._v(" "),
              _vm.totalItems
                ? _c(
                    "div",
                    { staticClass: "pagination" },
                    [
                      _c("b-pagination", {
                        attrs: {
                          limit: "3",
                          "total-rows": _vm.totalItems,
                          "per-page": _vm.perPage,
                          size: "sm",
                        },
                        on: { change: _vm.onPageChange },
                        model: {
                          value: _vm.currentPage,
                          callback: function ($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.totalItems && !_vm.noInputPaginator
                ? _c(
                    "div",
                    { staticClass: "input_pagination" },
                    [
                      _c("base-input-pagination", {
                        on: { pagination: _vm.onInputPagination },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.infomodalName,
              title: _vm.modalTitle,
              size: "lg",
              scrollable: "",
              "ok-only": "",
              "ok-variant": "lisaweb",
              "header-bg-variant": "lisaweb",
              "no-close-on-backdrop": "",
            },
            on: { hidden: _vm.onModalHidden },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "pt-1", lazy: "" },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        attrs: {
                          title: tab.text,
                          active: index === _vm.tabIndex,
                          "title-item-class": "lisaweb",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTab(tab, index)
                          },
                        },
                      },
                      [
                        _c(
                          "keep-alive",
                          [
                            _vm.selected === tab.name
                              ? _c(_vm.selected, {
                                  tag: "component",
                                  attrs: {
                                    resourceId: _vm.modalResourceId,
                                    dbRowData: _vm.dbRowData,
                                  },
                                  on: { fetch: _vm.fetch },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }